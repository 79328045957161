.dot-figure-node {
    --node-stroke-length: 5;
    --node-desired-width: 2;

    --node-remaining-mag: calc(var(--node-desired-width) - var(--node-stroke-length));
    --node-remaining: calc(var(--node-remaining-mag) * 1px);
    --node-add-when-even: calc(-1 * 0.1px);
    --node-final-width: calc(var(--node-remaining) + var(--node-add-when-even));

    --node-stroke-length-px: calc(var(--node-stroke-length) * 1px);

    --node-background-color: #ffffff;
    --node-border-color: #000000;
}

.dotted-node-inside {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    border-radius: inherit;
}

.dotted-node-inside:after {
    z-index: 1;
    content: '';
    position: absolute;
    border-radius: inherit;
    left: var(--node-final-width);
    top: var(--node-final-width);
    right: var(--node-remaining);
    bottom: var(--node-remaining);
    border: var(--node-stroke-length-px) dashed var(--node-border-color);
    pointer-events: none;
}