.App-logo {
    height: 40vmin;
    pointer-events: none;
}


.jsoneditor-tree-inner {
    background-color: #1a292d;
}
    
.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    /* Aspect ratio */
    height: 0;
    overflow: hidden;
}


@keyframes crossfade {
    0% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    55% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.image-container {
    position: relative;
    height: 100%;
    width: 45%;
    flex: '0 1 auto';
}

.image-container div {
    animation-name: crossfade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: calc(var(--total-images) * 5s);
    background-size: cover;
    background-position: 80% 50%;
    position: absolute;
    width: 100%;
    height: 100%;
}

.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.jsoneditor-field {
    color: white !important;
}

.jsoneditor {
    border: 0px solid #eee !important;
}

.jsoneditor-treepath {
    background-color: #1e2836 !important;
}

.jsoneditor-frame {
    background-color: #1e2836 !important;
}

.jsoneditor-input {
    background: #1e2836 !important;
}

.jsoneditor-value {
    color: #beb3c0 !important;
}

.jsoneditor-menu {
    background-color: #1e2836 !important;
    border-bottom: 1px solid #14775e !important;
}

.jsoneditor-navigation-bar {
    background-color: #1e2836 !important;
    border-bottom: 1px solid #14775e !important;
}

div.jsoneditor-field[contenteditable=true]:focus,
div.jsoneditor-field[contenteditable=true]:hover,
div.jsoneditor-value[contenteditable=true]:focus,
div.jsoneditor-value[contenteditable=true]:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
    background-color: #1e1d1d !important;
    border: 1px solid #14775e !important;
    border-radius: 2px;
}

.react-flow__node {
    background-color: #372642;
    font-size: 1.3em;
    color: #eeeeee;
}

.react-flow__controls-button {
    border-bottom: 2px solid #2a2a2a;
    background-color: #605a5a;
}

.fontDesignInterface {
    font-family: 'Noto Sans', sans-serif;
}

.text-updater-node {
    height: 50px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    background: white;
}

.text-updater-node label {
    display: block;
    color: #777;
    font-size: 12px;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

/* FormDataCollector field delete button */

.deleteFormFieldSlider {
    --hide-left: 4em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    width: fit-content;
    min-width: calc(var(--hide-left) + 80px);

    position: absolute;
    left: calc(-1 * var(--hide-left));
}

.trashContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 70px;
    height: 100%;
    margin-right: 10px;

    transition: width 0.5s ease-in-out;
}

.formFieldPolygonLabel:hover .trashContainer {
    width: calc(var(--hide-left) + 80px);
}

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

.App-link {
    color: #61dafb;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


html,
body {
    --scrollbar-width: calc(100vw - 100%);

    padding: 0;
    margin: 0;
    /* background: #AAA; */
}

.sitegate {
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.sitegate-part {
    flex: 0 0 fit-content;
}

.sitegate-divider {
    height: 0.2cm;
    flex: 1 0 auto;
}

.sitegate-mheight {
    max-height: 2em;
}

/* .sitegate {
    position: absolute;
    height: 100vh;
    width: 100vw;
    --u: 10px;
    --c1: #fbf9fe;
    --c2: #76A0A6;
    --c3: #51697A;
    --gp: 50%/ calc(var(--u) * 16.9) calc(var(--u) * 12.8);

    filter: blur(8px);
    -webkit-filter: blur(8px);

    background:
        conic-gradient(from 122deg at 50% 85.15%, var(--c2) 0 58deg, var(--c3) 0 116deg, #fff0 0 100%) var(--gp),
        conic-gradient(from 122deg at 50% 72.5%, var(--c1) 0 116deg, #fff0 0 100%) var(--gp),
        conic-gradient(from 58deg at 82.85% 50%, var(--c3) 0 64deg, #fff0 0 100%) var(--gp),
        conic-gradient(from 58deg at 66.87% 50%, var(--c1) 0 64deg, var(--c2) 0 130deg, #fff0 0 100%) var(--gp),
        conic-gradient(from 238deg at 17.15% 50%, var(--c2) 0 64deg, #fff0 0 100%) var(--gp),
        conic-gradient(from 172deg at 33.13% 50%, var(--c3) 0 66deg, var(--c1) 0 130deg, #fff0 0 100%) var(--gp),
        linear-gradient(98deg, var(--c3) 0 15%, #fff0 calc(15% + 1px) 100%) var(--gp),
        linear-gradient(-98deg, var(--c2) 0 15%, #fff0 calc(15% + 1px) 100%) var(--gp),
        conic-gradient(from -58deg at 50.25% 14.85%, var(--c3) 0 58deg, var(--c2) 0 116deg, #fff0 0 100%) var(--gp),
        conic-gradient(from -58deg at 50% 28.125%, var(--c1) 0 116deg, #fff0 0 100%) var(--gp),
        linear-gradient(90deg, var(--c2) 0 50%, var(--c3) 0 100%) var(--gp);
} */

.headline {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 10vw;
}

.inset {
    position: absolute;
    inset: 0;
}

.white {
    color: white;
}

.gray {
    color: #446;
}

.full {
    height: 100vh;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gradient {
    background: linear-gradient(rgba(14, 62, 151, 0.5) 50%, black);
}